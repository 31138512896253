<template>

  <el-dialog title="赠送配置" :visible.sync="show" :lock-scroll="false" :close-on-click-modal="false" width="550px" :before-close="dialogClose">
    <div class="top">地区：{{item.regionName}}</div>
    <div class="top">优惠券
      <el-button type="text" icon="el-icon-plus" style="font-size:20px" @click="addOne"></el-button>
    </div>
    <el-form inline :model="form" ref="form" @submit.native.prevent>
      <el-form-item v-for="(item,i) in form.formList" :prop="'formList.' + i + '.vouchersId'" :rules="{required: true, message: '优惠券不能为空', trigger: 'change'}">
        <el-select v-model="item.vouchersId" placeholder="请选择">
          <el-option v-for="opt in options" :key="opt.vouchersId" :label="opt.vouchersTitle" :value="opt.vouchersId"></el-option>
        </el-select>
        <el-input-number v-model="item.quantity" :min="1" label="描述文字" style="width:150px;margin:0 20px"></el-input-number>
        <el-button type="text" icon="el-icon-delete" style="font-size:20px;color:red" @click="deleteOne(i,item)"></el-button>
        <!-- <el-button type="text" icon="el-icon-plus" style="font-size:20px;margin-left:20px" @click="addOne"></el-button> -->
      </el-form-item>
    </el-form>
    <div style="margin-top:30px">
      <el-button @click="dialogClose">关闭</el-button>
      <el-button v-debounce='submit' type="primary">保存</el-button>
    </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { addOrUpdateVouchersGiveConfig, deleteVouchersGiveConfig, getVouchersGiveConfigList, getVouchersList } from '@/api/setting'
export default {
  name: 'send',
  props: ['show', 'item'],
  data() {
    return {
      value: '',
      num: 1,
      options: [],
      form: {
        formList: [],
      }
    }
  },
  created() {
    this.getVouchersList()
  },
  methods: {
    addOne() {
      let form = {
        vouchersId: '',
        regionId: this.item.regionId,
        quantity: 1
      }
      this.$set(this.form.formList, this.form.formList.length, form)
    },
    deleteOne(i, item) {
      if (!item.id) {
        this.form.formList.splice(i, 1)
        return
      }

      this.$confirm('确认删除此项优惠券?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteVouchersGiveConfig({ id: item.id }).then(res => {
          if (res.code != 200) {
            this.$message.error(res.msg || '删除失败')
            return
          }
          this.form.formList.splice(i, 1)
          this.$message.success('删除成功')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    submit() {
      if (!this.$validate('form')) return
      addOrUpdateVouchersGiveConfig(this.form.formList).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '保存失败')
          return
        }
        this.$message.success('保存成功')
        this.dialogClose()
      })
    },

    getVouchersList() {
      let param = {
        vouchersType: 0,
        usedArea: this.item.regionCode
      };
      getVouchersList(param).then((response) => {
        if (response.code == 200) {
          this.options = response.data;
        } else {
          this.$message.error({
            title: '错误',
            message: response.msg,
            duration: 1500
          });
        }
      })
      getVouchersGiveConfigList({ regionId: this.item.regionId }).then(res => {
        this.form.formList = res.data || []
        console.log(res)
      })
    },
    dialogClose() {
      this.$emit('update:show', false)
    },
  }
}
</script>

<style lang="scss" scoped>
.top {
  text-align: left;
  font-size: 16px;
  margin: 20px 0;
}
</style>