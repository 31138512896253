<template>
  <div>
    <el-table :data="list" height style="max-width: 100%;margin-top:15px;box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);height:calc(100vh - 200px);overflow:auto">
      <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="regionName" align="center" label="省区" width="350px"></el-table-column>
      <el-table-column prop="content" align="center" label="赠送内容" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" class="el-icon-setting" @click="editDetail(scope.row)">设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination style="margin-top:10px;" layout="total, prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
    <Send :show.sync="show" v-if="show" :item="item"></Send>
  </div>
</template>

<script>
import { getVouchersGiveConfigPage } from '@/api/setting'
import Send from './components/Send'
export default {
  name: 'giftConfiguration',
  components: { Send },
  data() {
    return {
      search: {
        pageNum: 1,
        pageSize: 10,
      },
      list: [],
      total: 0,
      show: false,
      item: {}
    }
  },
  created() {
    this.getList()
  },
  watch: {
    show(val) {
      if (!val) this.getList()
    }
  },
  methods: {
    getList() {
      getVouchersGiveConfigPage(this.search).then(res => {
        this.list = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(val) {
      this.search.pageSize = val
      this.handleCurrentChange(1)
    },
    handleCurrentChange(val) {
      this.search.pageNum = val
      this.getList()
    },
    editDetail(row) {
      this.item = row
      this.show = true
    }
  }
}
</script>

<style>
</style>